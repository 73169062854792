body {
	padding: 0;
	margin: 0;
}

.main {
	min-height: 100vh;
}

.white-logo {
	padding-right: 16px;
	height: 30px;
}

.site-header {
	position: sticky;
	width: 100%;
	z-index: 1;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.rbc-time-view {
	border: 0;
}

.login-logo {
	height: 90px;
	padding-bottom: 16px;
	display: table;
	margin: auto;
}

.login-form {
	display: flex;
	height: 100vh;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.login-form-container {
	width: 300px;
}

.ant-picker,
.ant-input-number {
	width: 100%;
}

.home-menu button.ant-btn {
	width: 200px;
}

.ant-table-summary {
	background: #fafafa;
}

input#addWork_total {
	color: #fff;
	font-weight: bold;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
	background-color: #1668dc;
	border-radius: 0;
	padding: 8px 5px;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
	background-color: #1668dc;
}

.add-order-list {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
}

.full-width,
form#addOrder .full-width.ant-row.ant-form-item {
	width: 100%;
}

.order-form-3-columns {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
}

.rbc-time-content>.rbc-day-slot,
.rbc-today {
	background: #171717;
	color: #8f8f8f !important;
}

.rbc-toolbar button {
	color: #e8e8e8;
	background: #151515;
	border-color: #000;
}

.rbc-toolbar button.rbc-active {
	background: #000;
	border-color: #000;
}

span.rbc-toolbar-label,
.rbc-time-content>.rbc-day-slot,
.rbc-row {
	background: #151515;
}

span.rbc-label {
	color: #b7b7b7;
}

.rbc-day-slot .rbc-time-slot,
.rbc-time-content>*+*>*,
.rbc-time-header-content,
.rbc-timeslot-group,
.rbc-day-bg+.rbc-day-bg,
.rbc-month-row+.rbc-month-row,
.rbc-month-view,
.rbc-header,
.rbc-header+.rbc-header,
.rbc-agenda-view table.rbc-agenda-table,
.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td,
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell,
.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr,
.rbc-agenda-view table.rbc-agenda-table thead>tr>th {
	border-color: #353535;
}

.rbc-current-time-indicator {
	background-color: #ff1818;
}

.rbc-date-cell>a,
.rbc-date-cell>a:active,
.rbc-date-cell>a:visited,
.rbc-header {
	color: #e1e1e1;
}

.rbc-off-range-bg {
	background-color: #3c3c3c;
}

.rbc-agenda-view table.rbc-agenda-table td {
	color: #eee;
}

.autocomplete-dropdown-container {
	background: #222;
	position: absolute;
	z-index: 9999999;
	color: #fff;
}

.autocomplete-dropdown-container div {
	padding: 3px 10px;
	font-size: 12px;
	cursor: pointer;
}

.suggestion-item--active {
	background: #0f0f0f;
}

.rbc-event-content {
	font-size: 11px;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
	display: block;
	overflow: hidden;
	text-overflow: clip;
	white-space: nowrap;
}

.rbc-time-header {
	display: none;
}

.rbc-time-content {
	border: 0;
}

@media (max-width: 800px) {
	.ant-table {
		overflow-x: auto;
	}
}

@media (max-width: 600px) {
	.add-order-list.full-width>div {
		width: 100%;
	}

	.ant-form-item {
		margin-bottom: 8px;
	}

	.site-header {
		top: auto;
		bottom: 0;
		position: fixed;
		z-index: 4;
		padding: 0;
		box-shadow: 0px -2px 20px 0px #000000cf;
		padding: 0 0 10px;
	}

	form#addOrder .order-form-3-columns .ant-row.ant-form-item,
	form#addOrder .ant-row.ant-form-item {
		width: 100%;
		flex-wrap: nowrap;
		margin-bottom: 3px;
	}

	form#addOrder.ant-form .ant-form-item .ant-form-item-label {
		flex: 0 0 auto;
		max-width: none;
		padding-bottom: 0;
		padding-right: 10px;
	}

	form#addOrder.ant-form .ant-form-item .ant-form-item-control {
		flex: 1 1;
		max-width: none;
	}

	img.white-logo,
	.ant-layout-sider-trigger {
		display: none;
	}

	.rbc-btn-group {
		display: inline-block;
		white-space: normal;
		margin-bottom: 15px;
	}

	span.rbc-toolbar-label {
		margin-bottom: 15px;
		font-weight: bold;
	}

	.rbc-toolbar button {
		padding: 15px 10px;
		font-size: 14px;
	}

	.ant-layout-content {
		margin-bottom: 58px !important;
	}

	.ant-table-thead>tr>th {
		padding: 16px 8px;
	}

	.rbc-event-label {
		display: none;
	}

	.mobile-menu {
		display: flex;
		justify-content: space-evenly;
		position: relative;
		width: 100%;
		padding: 0 8px;
	}


	.mobile-menu-item a,
	.mobile-menu-item span {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		line-height: normal;
		color: #fff;
		font-size: 11px;
	}

	.rbc-date-cell {
		padding: 10px 5px;
	}
}

.ant-modal-wrap {
	z-index: 1001 !important;
}

.rbc-event-content .foreman-name-title {
	background-color: #fff;
	padding: 0 3px;
	color: red;
}

.rbc-now.rbc-current {
	background-color: #b53737;
	font-weight: 800;
}